import React from 'react'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'
import css from '@styled-system/css'
import styled from '@emotion/styled'
import { lazy as loadable } from '@loadable/component'

import { QueryDrawer } from './query-drawer'

const LoginBox = loadable(() => import('./login-box'))

const StyledHeading = styled.h3`
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
  background: linear-gradient(180deg, #fff 0%, #9090ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  ${css({
    fontSize: 6,
    pb: 0,
  })};
`

export function LoginDrawer() {
  const translate = Herz.I18n.useTranslate()

  const { authenticated } = Herz.Auth.useSession()

  // Login is available on the landing-page as a returning user.
  if (authenticated) {
    return null
  }

  return (
    <QueryDrawer activeQueryName="login" title={translate('login-page.title')}>
      <Common.Box pb={6} color="g-text">
        <Common.Box pt="20px">
          <StyledHeading>{translate('welcome-back.heading')}</StyledHeading>
          <Common.Box
            as="img"
            src={translate('welcome-back.image')}
            width="100%"
            height="auto"
            borderRadius="16px"
            my={0}
          />
        </Common.Box>
        <LoginBox />
      </Common.Box>
    </QueryDrawer>
  )
}

Herz.I18n.Loader.preload(
  ['login-page.title', 'welcome-back.image', 'welcome-back.heading'],
  LoginDrawer
)

// for @loadable/components
export default LoginDrawer
